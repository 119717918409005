import { createSelector } from "@reduxjs/toolkit";
import { flatMap, remove, sumBy } from "lodash";
import difference from "lodash/difference";
import filter from "lodash/filter";
import first from "lodash/first";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import last from "lodash/last";
import map from "lodash/map";
import merge from "lodash/merge";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import values from "lodash/values";

import type { TBalance } from "../../@mm-ux/types/Common";
import { endpoints } from "../../api/api";
import type { TApiLockTypes } from "../../api/types/clientPortal";
import { type TCommodity, EnumCommodity } from "../../common/types/commodity";
import type { PartialRecord } from "../../common/types/utility";
import { convertToInputCommodityOz } from "../../common/util/commodityUtils";
import { fromStringToDateStringObj, getEachDayOfMonthBetween } from "../../common/util/dateUtils";
import { selectLysaRateByCommodity, selectMaybeActiveAccountEid } from "../app/appSelectors";
import { createSelectorOfMaxCacheSize, createValueEqualitySelector } from "../selectorHelpers";
import type { RootState } from "../store";

import type {
  TInterestAndTaxDisplayData,
  TReduxBondHolder,
  TReduxLessor,
  TReduxLock,
  TReduxPosition,
  TReduxTaxAndInterest,
} from "./positionsTypes";

export const selectActiveRawPosition = (state: RootState) => {
  const activeEid = selectMaybeActiveAccountEid(state);

  return endpoints.getAccountPosition.select(activeEid)(state)?.data;
};

export const selectRawActiveTaxAndInterest = (state: RootState) => {
  const activeEid = selectMaybeActiveAccountEid(state);

  return endpoints.getInterestAndTax.select(activeEid)(state)?.data;
};

export const selectActivePosition = createSelector(selectActiveRawPosition, (rawPosition) => {
  if (!rawPosition) return undefined;

  const positionData: TReduxPosition = {
    balances: [],
    lessor: [],
    bondHolder: [],
    locks: [],
  };

  forEach(rawPosition.position, (positionObj, commodity) => {
    if (!positionObj) return;

    positionData.balances.push({
      ...positionObj.balance,
      commodity: commodity as TCommodity,
    });

    const lessorInfo: Array<TReduxLessor> = map(positionObj?.lessor, (value, key) => ({
      ...value,
      idesc: key,
      commodity: commodity as TCommodity,
    }));

    positionData.lessor.push(...lessorInfo);

    const bondHolderInfo: Array<TReduxBondHolder> = map(positionObj?.bond_holder, (value, key) => ({
      ...value,
      idesc: key,
      commodity: commodity as TCommodity,
    }));

    positionData.bondHolder.push(...bondHolderInfo);

    forEach(positionObj?.lock, (lockCollection, lockType) => {
      if (!lockCollection) return;

      const lockHolderInfo: Array<TReduxLock> = map(lockCollection, (value, key) => ({
        ...value,
        idesc: key,
        commodity: commodity as TCommodity,
        lockType: lockType as TApiLockTypes,
      }));

      positionData.locks.push(...lockHolderInfo);
    });
  });

  return positionData;
});

const selectActiveTaxAndInterest = createSelector(selectRawActiveTaxAndInterest, (rawTaxAndInterest) => {
  if (!rawTaxAndInterest) return undefined;
  const modifiedPayload = map(rawTaxAndInterest.data, (interestAndTaxFromApi) => ({
    account: interestAndTaxFromApi.account,
    commodity: interestAndTaxFromApi.asset_class,
    date: interestAndTaxFromApi.task_date,
    subaccount: interestAndTaxFromApi.subaccount,
    tax: parseFloat(interestAndTaxFromApi.tax) || 0,
    grossInterest: parseFloat(interestAndTaxFromApi.gross_interest) || 0,
    netInterest: parseFloat(interestAndTaxFromApi.net_interest) || 0,
  })) as Array<TReduxTaxAndInterest>;

  const nonEmptyValues = remove(modifiedPayload, (item) => item.tax || item.grossInterest || item.netInterest);

  return nonEmptyValues;
});

export const selectInterestEarnedRanges = (state: RootState) => state.app.interestEarnedRangeByEid;

export const selectInterestEarnedRangesForActiveEid = createSelector(
  [selectInterestEarnedRanges, selectMaybeActiveAccountEid],
  (interestEarnedRanges, maybeActiveEid) => interestEarnedRanges[maybeActiveEid] || [0, 0],
);

export const selectLocksFromActivePosition = createSelector(selectActivePosition, (activePosition) => {
  if (!activePosition) return [];

  return activePosition.locks || [];
});

export const selectBondLocksFromActivePosition = createSelector(selectLocksFromActivePosition, (locks) =>
  filter(locks, (lock) => lock.lockType === "lock/global-bond"),
);

export const selectBondLocksFromActivePositionFromListOfCommodity = createValueEqualitySelector(
  [selectBondLocksFromActivePosition, (_state, commodity: Array<TCommodity>) => commodity],
  (locks, commodity) => filter(locks, (lock) => commodity.includes(lock.commodity)),
);

export const selectBalancesFromActivePosition = createSelector(selectActivePosition, (activePosition) => {
  if (!activePosition) return [];

  return activePosition.balances || [];
});

export const selectTotalUSDBalanceForPurchaseFromActivePosition = createSelector(
  [selectBalancesFromActivePosition],
  (balances) => {
    const maybeUsdBalance = balances.find((balance) => balance.commodity === EnumCommodity.Usd);

    if (!maybeUsdBalance) return 0;

    return maybeUsdBalance["hold/deposit_for_purchase"] ?? 0;
  },
);

export const selectLessorFromActivePosition = createSelector(selectActivePosition, (activePosition) => {
  if (!activePosition) return [];

  return activePosition.lessor || [];
});

export const selectBondHolderFromActivePosition = createSelector(selectActivePosition, (activePosition) => {
  if (!activePosition) return [];

  return activePosition.bondHolder || [];
});

export const selectBalancesWithUsdAdjustedForWithdrawals = createSelector(
  selectBalancesFromActivePosition,
  (balances) =>
    map(balances, (balance) =>
      balance.commodity === EnumCommodity.Usd
        ? { ...balance, "hold/storage": balance["hold/deposit_for_withdrawal"] }
        : balance,
    ),
);

export const selectBalanceFromListOfCommodity = createValueEqualitySelector(
  [
    selectBalancesWithUsdAdjustedForWithdrawals,
    (_state: RootState, commodity: Array<TCommodity>) => commodity,
    (state: RootState, commodity: Array<TCommodity>) =>
      selectBondLocksFromActivePositionFromListOfCommodity(state, commodity),
  ],
  (balances, commodity, locks) => {
    const filteredBalances = filter(balances, (balance) => commodity.indexOf(balance.commodity) > -1);
    const groupedBalances = keyBy(filteredBalances, "commodity");

    const groupedLocks = keyBy(locks, "commodity");

    const adjustedBalancesWithBondInfo = map(values(groupedBalances), (balance) => {
      if (!groupedLocks[balance.commodity])
        return {
          ...balance,
          "hold/bondYield": 0,
        };

      const lockAmount = groupedLocks[balance.commodity].amount;
      const newYieldHold = Math.max(0, balance["hold/yield"] + lockAmount);

      return {
        ...balance,
        "hold/bondYield": balance["hold/yield"] - newYieldHold,
        "hold/yield": newYieldHold,
      };
    });

    return adjustedBalancesWithBondInfo;
  },
);

export const selectCommodityOnLease = createSelector(selectLessorFromActivePosition, (lessor) =>
  uniq(
    map(
      filter(lessor, (lease) => lease.status === "active"),
      "commodity",
    ),
  ),
);

export const selectCommodityOnBond = createSelector(selectBondHolderFromActivePosition, (bondHolder) =>
  uniq(
    map(
      filter(bondHolder, (bond) => bond.status === "active"),
      "commodity",
    ),
  ),
);

export const selectLessorFromListOfCommodity = createValueEqualitySelector(
  [selectLessorFromActivePosition, (_state: RootState, commodity: Array<TCommodity>) => commodity],
  (lessors, commodity) => filter(lessors, (lessor) => commodity.indexOf(lessor.commodity) > -1),
);

export const selectBondHolderFromListOfCommodity = createValueEqualitySelector(
  [selectBondHolderFromActivePosition, (_state: RootState, commodity: Array<TCommodity>) => commodity],
  (bondHolders, commodity) => filter(bondHolders, (bondHolder) => commodity.indexOf(bondHolder.commodity) > -1),
);

// export const selectCombinedBondHolderInfoByCommodity = createSelector(
//   [
//     (state: RootState, eid: string, commodity: Array<TCommodity>) =>
//       selectBondHolderFromListOfCommodity(state, eid, commodity)
//   ],
//   (listOfBondHolder: Array<TReduxBondHolder>) => {
//     const baseObj: {
//       [commodity in TCommodity]?: Pick<
//         TReduxBondHolder,

export const selectCombinedLessorInfoByCommodity = createValueEqualitySelector(
  [(state: RootState, commodity: Array<TCommodity>) => selectLessorFromListOfCommodity(state, commodity)],
  (listOfLessor: Array<TReduxLessor>) => {
    const baseObj: {
      [commodity in TCommodity]?: Pick<
        TReduxLessor,
        "commodity" | "lessor/allocation" | "lessor/cma" | "lessor/onlease"
      >;
    } = {};

    const mapOfLease = reduce(
      listOfLessor,
      (prev, curr) => ({
        ...prev,
        [curr.commodity]: {
          commodity: curr.commodity,
          "lessor/allocation": (prev?.[curr.commodity]?.["lessor/allocation"] || 0) + curr["lessor/allocation"],
          "lessor/cma": (prev?.[curr.commodity]?.["lessor/cma"] || 0) + curr["lessor/cma"],
          "lessor/onlease": (prev?.[curr.commodity]?.["lessor/onlease"] || 0) + curr["lessor/onlease"],
        },
      }),
      baseObj,
    );

    return values(mapOfLease);
  },
);

export const selectCombinedBondHolderInfoByCommodity = createValueEqualitySelector(
  [(state: RootState, commodity: Array<TCommodity>) => selectBondHolderFromListOfCommodity(state, commodity)],
  (listOfBondHolder: Array<TReduxBondHolder>) => {
    const baseObj: {
      [commodity in TCommodity]?: Pick<TReduxBondHolder, "bond_holder/onloan">;
    } = {};

    const mapOfBondHolder = reduce(
      listOfBondHolder,
      (prev, curr) => ({
        ...prev,
        [curr.commodity]: {
          commodity: curr.commodity,
          "bond_holder/onloan": (prev?.[curr.commodity]?.["bond_holder/onloan"] || 0) + curr["bond_holder/onloan"],
        },
      }),
      baseObj,
    );

    return values(mapOfBondHolder);
  },
);

export const selectBalanceDisplayInfoByCommodity = createValueEqualitySelector(
  [
    (state: RootState, commodity: Array<TCommodity>) => selectCombinedLessorInfoByCommodity(state, commodity),
    (state: RootState, commodity: Array<TCommodity>) => selectBalanceFromListOfCommodity(state, commodity),
    (state: RootState, commodity: Array<TCommodity>) => selectCombinedBondHolderInfoByCommodity(state, commodity),
  ],
  (combinedLessorObj, balanceObjList, bondHolder) => {
    const merged = merge(
      keyBy(combinedLessorObj, "commodity"),
      keyBy(balanceObjList, "commodity"),
      keyBy(bondHolder, "commodity"),
    );

    const defaultValues = {
      "lessor/allocation": 0,
      "lessor/cma": 0,
      "lessor/onlease": 0,
      "hold/storage": 0,
      "hold/settlement": 0,
      "hold/bondYield": 0,
      "hold/tax": 0,
      "hold/yield": 0,
      "bond_holder/onloan": 0,
    } as Omit<TBalance, "commodity">;

    const mappedWithDefaultValues = map(values(merged), (value) => ({
      ...defaultValues,
      ...value,
    })) as TBalance[];

    return filter(
      mappedWithDefaultValues,
      (value) =>
        value["lessor/allocation"] !== 0 ||
        value["hold/yield"] !== 0 ||
        value["hold/storage"] !== 0 ||
        value["hold/bondYield"] !== 0 ||
        value["bond_holder/onloan"] !== 0,
    ) as TBalance[];
  },
);

export const selectBalanceDisplayInfoByCommodityWithNegativesChangedToZero = createValueEqualitySelector(
  [(state: RootState, commodity: Array<TCommodity>) => selectBalanceDisplayInfoByCommodity(state, commodity)],
  (balanceDisplayInfo) =>
    map(balanceDisplayInfo, (value) => {
      const valueCopy = {
        ...value,
      } as TBalance;

      if (valueCopy["lessor/allocation"] < 0) valueCopy["lessor/allocation"] = 0;
      if (valueCopy["lessor/onlease"] < 0) valueCopy["lessor/onlease"] = 0;
      if (valueCopy["hold/storage"] < 0) valueCopy["hold/storage"] = 0;
      if (valueCopy["hold/bondYield"] != null && valueCopy["hold/bondYield"] < 0) valueCopy["hold/bondYield"] = 0;
      if (valueCopy["bond_holder/onloan"] != null && valueCopy["bond_holder/onloan"] < 0)
        valueCopy["bond_holder/onloan"] = 0;

      return valueCopy;
    }) as TBalance[],
);

export const selectBalanceDisplayInfoByCommodityWithBondsFiltered = createValueEqualitySelector(
  [
    (state: RootState, commodity: Array<TCommodity>) =>
      selectBalanceDisplayInfoByCommodityWithNegativesChangedToZero(state, commodity),
  ],
  (balanceDisplayInfo) =>
    map(balanceDisplayInfo, (value) => {
      if (value["bond_holder/onloan"] === 0 && value["hold/bondYield"] === 0) {
        return omit(value, ["bond_holder/onloan", "hold/bondYield"]);
      }

      return value;
    }),
);

export const selectCommodityThatHasEarnedInterest = createSelector(
  selectActiveTaxAndInterest,
  (maybeInterestAndTax) => {
    if (!maybeInterestAndTax) return [];

    return uniq(map(maybeInterestAndTax, "commodity"));
  },
);

export const selectNumMonthsOfInterestEarnedByActiveEid = createSelector(
  selectActiveTaxAndInterest,
  (maybeInterestAndTax) => {
    if (!maybeInterestAndTax) return 0;

    const months = map(maybeInterestAndTax, (item) => {
      const dateObj = new Date(Date.parse(item.date));

      return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}`;
    });

    return uniq(months).length;
  },
);

export const selectCombinedInterestAndTaxByMonthAndCommodity = createSelector(
  selectActiveTaxAndInterest,
  selectInterestEarnedRangesForActiveEid,
  (taxAndInterest, [startIndex, endIndex]) => {
    if (!taxAndInterest || taxAndInterest.length === 0) return [];

    const groupedOnMonth = values(
      groupBy(taxAndInterest, (item) => {
        const dateObj = new Date(Date.parse(item.date));

        return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}`;
      }),
    );

    const selectedMonths = groupedOnMonth.slice(startIndex, endIndex + 1);

    const groupedItems = values(flatMap(selectedMonths, (month) => values(groupBy(month, "commodity"))));

    const reduceArrayByCombiningNumericalValues = (arrOfIntAndTaxGrouped: (typeof groupedItems)[0]) =>
      arrOfIntAndTaxGrouped.length === 1
        ? {
            ...omit(arrOfIntAndTaxGrouped[0], "subaccount"),
            date: fromStringToDateStringObj(arrOfIntAndTaxGrouped[0].date).dateStr,
          }
        : reduce(arrOfIntAndTaxGrouped as Array<Omit<TReduxTaxAndInterest, "subaccount">>, (prev, curr) => ({
            account: curr.account,
            commodity: curr.commodity,
            date: fromStringToDateStringObj(curr.date).dateStr,
            tax: prev.tax ? prev.tax + curr.tax : curr.tax,
            grossInterest: prev.grossInterest ? prev.grossInterest + curr.grossInterest : curr.grossInterest,
            netInterest: prev.netInterest ? prev.netInterest + curr.netInterest : curr.netInterest,
          }));

    return map(groupedItems, reduceArrayByCombiningNumericalValues) as Array<Omit<TReduxTaxAndInterest, "subaccount">>;
  },
);

export const selectCombinedCummulativeInterest = createSelector(
  [selectCombinedInterestAndTaxByMonthAndCommodity],
  (interestAndTax) => {
    const netInterestTotals = {} as PartialRecord<TCommodity, number>;
    const grossInterestTotals = {} as PartialRecord<TCommodity, number>;

    const intAndTaxSortedByDate = sortBy(interestAndTax, (item) => new Date(item.date));

    const cumulativeInterest = map(intAndTaxSortedByDate, (intAndTax) => {
      const netInterestTotal = intAndTax.netInterest + (netInterestTotals[intAndTax.commodity] || 0);
      const grossInterestTotal = intAndTax.grossInterest + (grossInterestTotals[intAndTax.commodity] || 0);

      netInterestTotals[intAndTax.commodity] = netInterestTotal;
      grossInterestTotals[intAndTax.commodity] = grossInterestTotal;

      return {
        ...intAndTax,
        netInterest: grossInterestTotal,
        grossInterest: netInterestTotal,
      };
    });

    return cumulativeInterest;
  },
);

type TTaxAndInterest = Omit<TReduxTaxAndInterest, "subaccount">;

export const selectGroupedItemsByDateWithFillerDates = createSelector(
  selectCombinedCummulativeInterest,
  (intAndTaxCombined) => {
    const allCommodities = uniq(map(intAndTaxCombined, "commodity"));
    const [startDate, endDate] = [first(intAndTaxCombined)?.date ?? "", last(intAndTaxCombined)?.date ?? ""];
    const allDates = getEachDayOfMonthBetween(startDate, endDate);

    // create a map of each date to a map of each commodity to the item
    const dateThenCommodityMap: { [date: string]: { [commodity: string]: TTaxAndInterest } } = {};

    forEach(intAndTaxCombined, (item) => {
      dateThenCommodityMap[item.date] ??= {};
      dateThenCommodityMap[item.date][item.commodity] = item;
    });

    // for each date, if there is no item for a commodity, create a filler item
    forEach(allDates, (date, dateIndex) => {
      dateThenCommodityMap[date] ??= {};

      const presentCommodities = keys(dateThenCommodityMap[date]);
      const missingCommodities = difference(allCommodities, presentCommodities) as TCommodity[];

      forEach(missingCommodities, (commodity) => {
        const previousDate = allDates[dateIndex - 1];
        const previousItem = dateThenCommodityMap[previousDate]?.[commodity];

        // if there is no previous item, no earlier commodity is present, so we can't fill in the gap
        if (!previousItem) return;
        const fillerItem = {
          ...previousItem,
          date,
        };

        dateThenCommodityMap[date][commodity] = fillerItem;
      });
    });
    // unfold the map into an array of tax and interest items lists
    const commodityMapGroupedByDate = values(dateThenCommodityMap);

    return map(commodityMapGroupedByDate, values) as TTaxAndInterest[][];
  },
);

export const selectGroupedItemsByDateWithFillerDatesSortedByDate = createSelector(
  selectGroupedItemsByDateWithFillerDates,
  (groupedItems: TTaxAndInterest[][]) =>
    sortBy(groupedItems, (groupedItem) => new Date(first(groupedItem)?.date ?? "")),
);

export const selectLondonFixData = (state: RootState) => endpoints?.getLondonFix?.select()(state).data ?? {};

export const selectGroupedItemsByDateWithFillerDatesSortedByDateWithCommodityConversion = createSelectorOfMaxCacheSize(
  2,
)(
  [
    selectGroupedItemsByDateWithFillerDatesSortedByDate,
    selectLondonFixData,
    (_state: RootState, commodityToConvertTo: TCommodity | null) => commodityToConvertTo,
  ],
  (groupedInterestAndTax, londonFixData, commodityToConvertTo) =>
    map(groupedInterestAndTax, (groupedByDateItems) =>
      convertToInputCommodityOz(
        groupedByDateItems,
        ["netInterest", "grossInterest", "tax"],
        londonFixData,
        commodityToConvertTo,
      ),
    ),
);

export const selectInterestEarnedSinceInceptionByDate = createSelector(
  selectGroupedItemsByDateWithFillerDatesSortedByDateWithCommodityConversion,

  (intAndTaxCombined) =>
    map(intAndTaxCombined, (groupedByDateItems) =>
      reduce(
        groupedByDateItems,
        (prev, curr) => ({
          date: curr.date,
          netInterestByCommodity: {
            ...prev.netInterestByCommodity,
            [curr.commodity]: curr.netInterest,
          },
          grossInterestByCommodity: {
            ...prev.grossInterestByCommodity,
            [curr.commodity]: curr.netInterest,
          },
          taxByCommodity: {
            ...prev.taxByCommodity,
            [curr.commodity]: curr.tax,
          },
        }),
        {
          netInterestByCommodity: {},
          grossInterestByCommodity: {},
          taxByCommodity: {},
        },
      ),
    ) as TInterestAndTaxDisplayData[],
);

export const selectTotalInterestEarnedSinceInception = createSelector(
  selectInterestEarnedSinceInceptionByDate,
  (interestAndTax) => {
    const lastInterestPayment = reduce(
      interestAndTax,
      (acc, monthOfInterest) => ({
        ...acc,
        ...monthOfInterest.netInterestByCommodity,
      }),
      {},
    ) as Record<TCommodity, number>;

    return (
      lastInterestPayment || {
        gold: 0,
        silver: 0,
      }
    );
  },
);

export const selectLysaBalanceByCommodity = createValueEqualitySelector(
  [
    (state: RootState, commodity: TCommodity) =>
      selectBalanceDisplayInfoByCommodityWithNegativesChangedToZero(state, [commodity]),
  ],
  (balance) => sumBy(balance, "hold/yield"),
);

export const selectAverageWeightedYieldForCommodity = createSelector(
  [
    selectLessorFromActivePosition,
    selectBondHolderFromActivePosition,
    (_, commodity: TCommodity) => commodity,
    selectLysaRateByCommodity,
    selectLysaBalanceByCommodity,
  ],
  (lessorInfo, bondInfo, commodity, lysaRate, lysaBalance) => {
    const filteredLessorInfo = filter(lessorInfo, (item) => item.commodity === commodity && item.status === "active");
    const filteredBondInfo = filter(bondInfo, (item) => item.commodity === commodity && item.status === "active");

    // Calculate total oz and weighted yield for lease and bond
    const leaseOz = sumBy(filteredLessorInfo, "lessor/onlease");
    const bondOz = sumBy(filteredBondInfo, "bond_holder/onloan");

    const leaseWeightedYield = sumBy(filteredLessorInfo, (item) => item["lessor/onlease"] * item.net_rate);
    const bondWeightedYield = sumBy(
      filteredBondInfo,
      (item) => Number(item["bond_holder/onloan"]) * Number(item.net_loan_rate),
    );

    // Get LYSA rate for this commodity
    const lysaOz = lysaRate > 0 ? lysaBalance : 0;

    const totalOz = leaseOz + bondOz + lysaOz;
    const totalWeightedYield = leaseWeightedYield + bondWeightedYield + lysaOz * lysaRate;

    return totalOz ? totalWeightedYield / totalOz : 0;
  },
);

export const selectAverageWeightedYieldForLeases = createSelector(
  [selectLessorFromActivePosition, (_, commodity: TCommodity) => commodity],
  (lessorInfo, commodity) => {
    const filteredLessorInfo = filter(lessorInfo, (item) => item.commodity === commodity && item.status === "active");
    const totalOz = sumBy(filteredLessorInfo, "lessor/onlease");
    const totalWeightedYield = sumBy(filteredLessorInfo, (item) => item["lessor/onlease"] * item.net_rate);

    return totalOz ? totalWeightedYield / totalOz : 0;
  },
);

export const selectAverageWeightedYieldForBonds = createSelector(
  [selectBondHolderFromActivePosition, (_, commodity: TCommodity) => commodity],
  (bondInfo, commodity) => {
    const filteredBondInfo = filter(bondInfo, (item) => item.commodity === commodity && item.status === "active");
    const totalOz = sumBy(filteredBondInfo, "bond_holder/onloan");
    const totalWeightedYield = sumBy(
      filteredBondInfo,
      (item) => Number(item["bond_holder/onloan"]) * Number(item.net_loan_rate),
    );

    return totalOz ? totalWeightedYield / totalOz : 0;
  },
);
