import { type FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AsyncButton, Button } from "@monetarymetals/react-ux";
import { Auth } from "aws-amplify";

import AuthPage from "./AuthPage";
import PasswordInput from "./PasswordInput";
import type { ChallengedUser } from "./Router";

export default function RecoveryCode({
  email,
  setChallengedUser,
}: {
  email: string;
  setChallengedUser: (challengedUser: ChallengedUser | null) => void;
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleRecoveryCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const recoveryCode = formData.get("recovery-code") as string;

    setError("");
    setLoading(true);

    try {
      const user = await Auth.signIn(email);

      await Auth.sendCustomChallengeAnswer(user, recoveryCode);
      setChallengedUser(user);
    } catch (err) {
      const errorMessage = (err as Error).message;

      if (errorMessage.includes("expired") || errorMessage.includes("attempts")) {
        setError(errorMessage.split("error")![1].trim());
      } else {
        setError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthPage title={t("main-navigation.enter-recovery-code")}>
      <form className="flex flex-col gap-4" onSubmit={handleRecoveryCode}>
        <PasswordInput placeholder={t("Enter your Recovery Code")} name="recovery-code" required />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <div className="flex flex-col gap-2">
          <AsyncButton type="submit" loading={loading} loadingText={t("button.verifying")} minLoadTime={1500}>
            {t("button.submit")}
          </AsyncButton>
        </div>
        <Button
          variant="link"
          type="button"
          onClick={() => {
            setChallengedUser(null);
            navigate("/");
          }}
        >
          {t("button.backToSignIn")}
        </Button>
      </form>
    </AuthPage>
  );
}
