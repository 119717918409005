import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { domAnimation, LazyMotion } from "framer-motion";

import "./i18next";

import awsConfig from "./aws/awsConfig";
import Authenticator from "./components/auth/Authenticator";
import LoadingSpinner from "./components/LoadingSpinner";
import Toaster from "./components/Toaster";
import { authenticatedRouter } from "./router/authenticatedRouter";

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

function App() {
  return (
    <LazyMotion features={domAnimation} strict>
      <Authenticator>
        <Suspense fallback={<LoadingSpinner className="!animate-none" />}>
          <RouterProvider router={authenticatedRouter} />
        </Suspense>
      </Authenticator>
      <Toaster />
    </LazyMotion>
  );
}

export default App;
