import { createContext, type ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { Auth, type CognitoUser } from "@aws-amplify/auth";
import { setUser as setSentryUser } from "@sentry/react";
import { Hub } from "aws-amplify";

import { unauthenticatedRouter } from "../../router/unauthenticatedRouter";

export type CognitoUserWithAttributes = CognitoUser & {
  attributes: {
    email: string;
    sub: string;
  };
};

type AuthenticatorProps = {
  children: ReactNode;
};

type ContextType = {
  user: CognitoUserWithAttributes;
  signOut: () => void;
};

export const AuthenticatorContext = createContext<ContextType>({
  user: {} as CognitoUserWithAttributes,
  signOut: () => {},
});

export default function Authenticator({ children }: AuthenticatorProps) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<CognitoUserWithAttributes | null>(null);
  const dispatch = useDispatch();

  const loadCurrentUser = () => {
    Auth.currentAuthenticatedUser()
      .then(setUser)
      .catch(() => setUser(null))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCurrentUser();

    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      // eslint-disable-next-line default-case
      switch (payload.event) {
        case "signIn":
          // some sign in events don't pass the required attributes
          // in this case, we call sign in again manually so this one can be ignored
          if (payload.data?.attributes) setUser(payload.data);
          break;
        case "signOut":
          setUser(null);
          // reset the redux state
          dispatch({ type: "signOutUser" });

          break;
        case "tokenRefresh":
          loadCurrentUser();
          break;
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      setSentryUser({
        id: user.attributes.sub,
        email: user.attributes.email,
      });
    } else {
      setSentryUser(null);
    }
  }, [user]);

  const contextValue = useMemo(
    () => ({ user: user as CognitoUserWithAttributes, signOut: () => Auth.signOut() }),
    [user],
  );

  if (loading) return null;

  if (user) {
    return <AuthenticatorContext.Provider value={contextValue}>{children}</AuthenticatorContext.Provider>;
  }

  return <RouterProvider router={unauthenticatedRouter} />;
}
