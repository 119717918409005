import { useRouteError } from "react-router-dom";

import GenericErrorPage from "../GenericErrorPage";
import { LazyLoadError } from "../router/authenticatedRouter";

import StaleContentFallback from "./StaleContentFallback";

export default function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof LazyLoadError) return <StaleContentFallback />;

  return <GenericErrorPage />;
}
