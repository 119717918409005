import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { Button, Card } from "@monetarymetals/react-ux";
import { captureException } from "@sentry/react";
import { useSessionStorage } from "usehooks-ts";

const MAX_REFRESH_ATTEMPTS = 3;

export default function StaleContentFallback() {
  const { t } = useTranslation("common");
  const originalError = useRouteError();

  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const [refreshAttempts, setRefreshAttempts] = useSessionStorage("stale-content-fallback-refresh-attempts", 0);

  // attempt to refresh the page if it's stale
  useEffect(() => {
    if (refreshAttempts < MAX_REFRESH_ATTEMPTS) {
      setRefreshAttempts((attempts) => attempts + 1);
      window.location.reload();
    } else {
      setMaxAttemptsReached(true);
    }
  }, []);

  useEffect(() => {
    if (maxAttemptsReached) {
      // only log the error to sentry if we reached the max attempts
      captureException(new Error("Stale content fallback reached max attempts"), {
        originalException: originalError,
      });
    }
  }, [maxAttemptsReached]);

  if (!maxAttemptsReached) return null;

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-200">
      <div className="container max-w-xl">
        <Card className="flex flex-col items-center gap-4 p-8">
          <ArrowPathIcon className="h-9 w-auto text-muted-foreground" />
          <h1 className="text-center text-xl">{t("refreshPage.title")}</h1>
          <p className="text-center [textWrap:balance]">{t("refreshPage.message")}</p>
          <Button className="w-full" onClick={() => window.location.reload()}>
            {t("refreshPage.button")}
          </Button>
        </Card>
      </div>
    </div>
  );
}
