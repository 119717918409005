import {
  // eslint-disable-next-line no-restricted-imports
  toast,
} from "react-hot-toast";
import type { PayloadAction } from "@reduxjs/toolkit";
import { captureMessage } from "@sentry/react";
import { call, takeEvery } from "redux-saga/effects";

import {
  type ToastPayload,
  dismissToast,
  removeToast,
  toastCustom,
  toastError,
  toastLoading,
  toastSuccess,
} from "./toastsReducers";

export function* successSaga(action: PayloadAction<ToastPayload>) {
  const { message, options } = action.payload;

  yield call(toast.success, message, options);
}

export function* errorSaga(action: PayloadAction<ToastPayload>) {
  const { message, options } = action.payload;

  if (message?.toString()) {
    yield call(captureMessage, message.toString(), "error");
  }
  yield call(toast.error, message, options);
}

export function* loadingSaga(action: PayloadAction<ToastPayload>) {
  const { message, options } = action.payload;

  yield call(toast.loading, message, options);
}

export function* customSaga(action: PayloadAction<ToastPayload>) {
  const { message, options } = action.payload;

  yield call(toast.custom, message, options);
}

export function* dismissSaga(action: PayloadAction<string | undefined>) {
  const id = action.payload;

  if (id) yield call(toast.dismiss, id);
  else yield call(toast.dismiss);
}

export function* removeSaga(action: PayloadAction<string | undefined>) {
  const id = action.payload;

  if (id) yield call(toast.remove, id);
  else yield call(toast.remove);
}

/*
 ** This saga wraps the existing functionality of react-hot-toast
 ** to allow side-effects such as logging, analytics, replaying, etc.
 */
export function* toastsSaga() {
  yield takeEvery(toastSuccess, successSaga);
  yield takeEvery(toastError, errorSaga);
  yield takeEvery(toastLoading, loadingSaga);
  yield takeEvery(toastCustom, customSaga);
  yield takeEvery(dismissToast, dismissSaga);
  yield takeEvery(removeToast, removeSaga);
}
