import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRouteError } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Button, Card } from "@monetarymetals/react-ux";
import { captureException } from "@sentry/react";

import FeedbackDialog from "./components/FeedbackDialog";
import { selectCognitoId } from "./redux/app/appSelectors";

export default function GenericErrorPage() {
  const error = useRouteError();
  useEffect(() => {
    // log the error to sentry
    captureException(error);
  }, [error]);

  const { t } = useTranslation("common");
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const cognitoId = useSelector(selectCognitoId);
  const source = "errorBoundary";

  const handleFeedbackButtonClick = () => {
    setIsFeedbackDialogOpen(true);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-200">
      <div className="container max-w-xl">
        <Card className="flex flex-col items-center gap-4 p-8">
          <ExclamationCircleIcon className="h-9 w-auto text-muted-foreground" />
          <h1 className="text-center text-xl">{t("errorPage.title")}</h1>
          <p className="text-center [textWrap:balance]">{t("errorPage.message")}</p>
          <Button className="w-full" onClick={() => window.location.reload()}>
            {t("errorPage.button.refresh")}
          </Button>
          <Button
            variant="ghost"
            className="w-full"
            onClick={() => {
              if (cognitoId) {
                handleFeedbackButtonClick();
              } else {
                window.location.href = "mailto:accounts@monetary-meals.com";
              }
            }}
          >
            {t("errorPage.button.report-issue")}
          </Button>
          <FeedbackDialog
            source={source}
            isOpen={isFeedbackDialogOpen}
            onClose={() => setIsFeedbackDialogOpen(false)}
          />
        </Card>
      </div>
    </div>
  );
}
