export const ACCEPTED_COOKIES_KEY = "accepted-cookies";

function hasAcceptedCookies() {
  return localStorage.getItem(ACCEPTED_COOKIES_KEY) === "true";
}

export const compliantLocalStorage: typeof localStorage = {
  getItem(key: string) {
    if (hasAcceptedCookies() === false) {
      return null;
    }

    return localStorage.getItem(key);
  },
  setItem(key: string, value: string) {
    if (hasAcceptedCookies() === false) {
      return;
    }
    localStorage.setItem(key, value);
  },
  clear() {
    localStorage.clear();
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
  key(index) {
    return localStorage.key(index);
  },
  get length() {
    return localStorage.length;
  },
};
