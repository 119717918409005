import { createSelectorCreator, defaultMemoize } from "reselect";

const isEqual = (v1: unknown, v2: unknown) => {
  if (v1 === v2) {
    return true;
  }

  if (Array.isArray(v1) && Array.isArray(v2)) {
    if (v1.length !== v2.length) {
      return false;
    }

    return v1.every((val, index) => v2[index] === val);
  }

  return false;
};

export const createValueEqualitySelector = createSelectorCreator(defaultMemoize, isEqual);

export const createSelectorOfMaxCacheSize = (maxSize: number) => createSelectorCreator(defaultMemoize, { maxSize });
