import { createSelector } from "@reduxjs/toolkit";
import { flatMap, keyBy } from "lodash";
import filter from "lodash/filter";
import map from "lodash/map";
import uniq from "lodash/uniq";

import { endpoints } from "../../api/api";
import type { TApiAppAccountPayload } from "../../api/types/clientPortal";
import type { TCommodity } from "../../common/types/commodity";
import type { RootState } from "../store";

import type { TAppSliceState } from "./appTypes";

export const selectApp = (state: RootState): TAppSliceState => state.app;

export const selectCognitoId = createSelector(selectApp, (appState) => appState.cognitoId);

export const selectPolicy = (state: RootState) => {
  const cognitoId = selectCognitoId(state);

  return cognitoId ? endpoints.getPolicyForCognitoUser.select(cognitoId)(state)?.data : undefined;
};

export const selectAccountList = createSelector(selectPolicy, (policy) => {
  if (!policy) return [];

  return filter(
    uniq([...map(policy?.aclGroups, "account"), ...map(policy?.aclMenus, "entity")]),
    (value: string) => value && value !== "*",
  ) as string[];
});

export const selectAppSettings = createSelector(selectPolicy, (policy) => policy?.settings);

export const selectGlobalBanner = createSelector(selectAppSettings, (appSettings) => appSettings?.globalBanner);

export const selectLysaRate = createSelector(selectAppSettings, (appSettings) => appSettings?.lysaRate);

// right now we are only using gold lysa rate and this is coming from the appSettings
// in the near future this should come from a goldmine endpoint
// JB 2025-03-03
export const selectLysaRateByCommodity = createSelector(
  [selectLysaRate, (_state, commodity: TCommodity) => commodity],
  (lysaRate, commodity) => {
    if (!lysaRate || commodity !== "gold") return 0;

    return lysaRate;
  },
);

export const selectSignInsUntilMfaPrompt = createSelector(
  selectAppSettings,
  (appState) => appState?.signInsUntilMfaPrompt ?? 1,
);

export const selectLockedOut = createSelector(selectPolicy, (policy) => policy?.lockOut?.locked ?? false);

export const selectCookieConsent = createSelector(selectAppSettings, (appState) => appState?.cookieConsent);

export const selectAppMaintenanceStatus = createSelector(
  selectAppSettings,
  (appState) => appState?.maintenance ?? false,
);

export const selectAppIgnoredOpportunities = createSelector(
  selectAppSettings,
  (appState) => appState?.ignoredOpportunities ?? {},
);

export const selectActiveAccountIndex = createSelector(selectApp, (appState) => appState.activeAccount);

export const selectMaybeActiveAccountEid = createSelector(
  [selectAccountList, selectActiveAccountIndex],
  (accounts, accountIndex) => accounts[accountIndex] ?? "",
);

export const selectAllAccountInfoMap = (state: RootState) => {
  const accountList = selectAccountList(state);

  const accountInfoList = map(
    accountList,
    (account) => endpoints.getAccountInfo.select(account)(state)?.data ?? ({} as TApiAppAccountPayload),
  ).filter((accountInfo) => accountInfo.client_eid);

  return keyBy(accountInfoList, "client_eid");
};

export const selectAllAccountInfoQueries = (state: RootState) => {
  const accountList = selectAccountList(state);

  return map(accountList, (account) => endpoints.getAccountInfo.select(account)(state));
};

export const selectMaybeEmail = createSelector(selectApp, (appState) => appState.email);

export const selectUserName = createSelector(
  [selectMaybeActiveAccountEid, selectAllAccountInfoMap],
  (accountEid, accounts) => accounts[accountEid]?.name,
);

export const selectEmulateUser = createSelector(selectApp, (appState) => appState.emulateUser);

export const selectAclGroups = createSelector(selectPolicy, (policy) => policy?.aclGroups ?? []);

export const selectIsSystemAdmin = createSelector(selectAclGroups, (appState) =>
  appState.some(({ group, account }) => group === "systemAdmin" && account === "*"),
);

export const selectAclMenu = createSelector(selectPolicy, (policy) => policy?.aclMenus ?? []);

export const selectAclMenusByEid = createSelector([selectAclMenu, (_state, eid: string) => eid], (aclMenu, eid) =>
  flatMap(aclMenu, ({ entity, resource }) => (entity === eid ? resource : [])),
);
export const selectAclMenusByActiveEid = createSelector([selectAclMenu, selectMaybeActiveAccountEid], (aclMenu, eid) =>
  flatMap(aclMenu, ({ entity, resource }) => (entity === eid ? resource : [])),
);

export const selectPageTitle = createSelector(selectApp, (appState) => appState.pageTitle);

export const selectListOfMenuResources = createSelector(
  [selectMaybeActiveAccountEid, selectAclMenu],
  (maybeEid, policyObjs) => {
    if (!maybeEid) return [];

    return uniq(map(filter(policyObjs, { entity: maybeEid }), "resource"));
  },
);

export const selectListOfMenuResourcesByActiveEid = createSelector(
  selectMaybeActiveAccountEid,
  selectAclMenu,
  (eid, policyObjs) => selectListOfMenuResources.resultFunc(eid, policyObjs),
);

export const selectActiveAssetClassByPage = createSelector(
  [(_state, page: string) => page, selectApp],
  (page, appState) => appState.activeAssetClassByPage[page],
);

export const selectActiveConversion = createSelector(selectApp, (appState) => appState.activeConversion);
