import { type FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { type CognitoUser, Auth } from "@aws-amplify/auth";
import { AsyncButton, Button, Input } from "@monetarymetals/react-ux";

import AuthPage from "./AuthPage";
import type { ChallengedUser } from "./Router";

export default function ConfirmSignIn({
  challengedUser,
  setChallengedUser,
}: {
  challengedUser: CognitoUser;
  setChallengedUser: (user: CognitoUser | ChallengedUser | null) => void;
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleConfirmSignIn = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const code = formData.get("code") as string;

    setError("");
    setLoading(true);
    Auth.confirmSignIn(challengedUser, code, "SOFTWARE_TOKEN_MFA")
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthPage title={t("main-navigation.enter-mfa")}>
      <form className="flex flex-col gap-4" onSubmit={handleConfirmSignIn}>
        <Input type="number" placeholder={t("labels.enterMFACode")} name="code" autoComplete="one-time-code" required />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <AsyncButton type="submit" loading={loading} loadingText={t("button.submitting")}>
          {t("button.submit")}
        </AsyncButton>
        <Button
          variant="link"
          type="button"
          onClick={() => {
            setChallengedUser({
              challengeName: "DISABLE_MFA",
              email: challengedUser.getUsername(),
            });
            navigate("/disable-mfa");
          }}
        >
          {t("Have recovery code?")}
        </Button>
        <Button
          variant="link"
          type="button"
          onClick={() => {
            setChallengedUser(null);
            navigate("/");
          }}
        >
          {t("button.backToSignIn")}
        </Button>
      </form>
    </AuthPage>
  );
}
