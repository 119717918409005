import { type ButtonHTMLAttributes, type InputHTMLAttributes, type ReactNode, forwardRef, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { Button, cn, Input } from "@monetarymetals/react-ux";

export interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({ className, buttonProps, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  let buttonContent: ReactNode = showPassword ? (
    <EyeSlashIcon className="h-5 w-auto" />
  ) : (
    <EyeIcon className="h-auto w-5" />
  );

  if (buttonProps?.children) {
    buttonContent = buttonProps.children;
  }

  return (
    <div className="flex rounded">
      <Input
        ref={ref}
        className={cn("rounded-r-none", className)}
        type={showPassword ? "text" : "password"}
        {...props}
      />
      <Button
        variant="ghost"
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="rounded-l-none border"
        {...buttonProps}
      >
        {buttonContent}
      </Button>
    </div>
  );
});

PasswordInput.displayName = "PasswordInput";
PasswordInput.defaultProps = {
  buttonProps: {},
};

export default PasswordInput;
