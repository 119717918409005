/* eslint-disable react/display-name */
import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { setPageTitle } from "../../redux/app/appReducers";
import { selectListOfMenuResources } from "../../redux/app/appSelectors";

type TPageSettings = {
  title: string;
  permissions: string | null;
};

export const withViewSettings =
  (settings: TPageSettings) =>
  <P extends object>(ViewComponent: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const dispatch = useDispatch();
    const userPermissions = useSelector(selectListOfMenuResources);

    useLayoutEffect(() => {
      dispatch(setPageTitle(settings.title));
    }, [settings.title]);

    if (settings.permissions && !userPermissions.includes(settings.permissions))
      return <Navigate to={userPermissions.includes("balance") ? "/balance" : "/settings"} />;

    return <ViewComponent {...props} />;
  };
