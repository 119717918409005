import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import {
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from "@sentry/react";

const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.env.VITE_ENV;

export const initSentry = () => {
  if (environment && dsn) {
    init({
      environment,
      dsn,
      integrations: [
        browserTracingIntegration(),
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", import.meta.env.VITE_API_URL],
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0,
      /*
       ** If you're not already sampling the entire session,
       ** change the sample rate to 100% when sampling sessions where errors occur.
       */
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
