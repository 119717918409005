import { type FormEvent, type MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { AsyncButton, Button, Input } from "@monetarymetals/react-ux";

import AuthPage from "./AuthPage";
import { useSetChallengedUser } from "./Router";

export default function ForgotPassword() {
  const { setChallengedUser } = useSetChallengedUser();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleForgotPassword = async (e: FormEvent<HTMLFormElement> | MouseEvent) => {
    e.preventDefault();

    setError("");
    setLoading(true);
    Auth.forgotPassword(email)
      .then(() => {
        setChallengedUser({
          challengeName: "RESET_PASSWORD",
          email,
        });
        navigate("/reset-password");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthPage title={t("main-navigation.forgot-password")}>
      <form className="flex flex-col gap-4" onSubmit={handleForgotPassword}>
        <Input
          type="email"
          autoComplete="email"
          name="email"
          placeholder={t("Enter your Email")}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <AsyncButton type="submit" loading={loading} loadingText={t("button.sendingCode")}>
          {t("button.sendCode")}
        </AsyncButton>
        <Button variant="link" type="button" onClick={() => navigate("/")}>
          {t("button.backToSignIn")}
        </Button>
      </form>
    </AuthPage>
  );
}
