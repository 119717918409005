import { type Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { api } from "../api/api";

import analyticReducer from "./analytics/analyticsReducers";
import appReducer from "./app/appReducers";
import rootSagas from "./rootSagas";

interface asyncReducerType {
  [sliceName: string]: Reducer;
}

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, api.middleware];

function createReducer(asyncReducers: asyncReducerType) {
  return combineReducers({
    [api.reducerPath]: api.reducer,
    app: appReducer,
    analytic: analyticReducer,
    ...asyncReducers,
  });
}

const store = configureStore({
  reducer: createReducer({}),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  devTools: !import.meta.env.PROD,
});

function reducerManagerCreator(storeInstance: typeof store) {
  const asyncReducerMap: asyncReducerType = {};

  const injectReducer = (sliceName: string, reducer: Reducer) => {
    if (!asyncReducerMap?.sliceName) {
      asyncReducerMap[sliceName] = reducer;
    }
    storeInstance.replaceReducer(createReducer(asyncReducerMap));
  };

  return injectReducer;
}

export const reducerManager = reducerManagerCreator(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSagas);

export default store;
