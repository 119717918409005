import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import Backend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { compact } from "lodash";

const { VITE_ENV = "development", VITE_TRANSLATIONS_BASE_URL = "/locales/{{lng}}/{{ns}}.json?v=" } = import.meta.env;

export const namespaces = [
  "common",
  "balances",
  "statement",
  "investments",
  "termsandconditions",
  "opportunity",
  "transactions",
];

const i18nConfig = i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    load: "languageOnly",
    ns: namespaces,
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: compact([
        VITE_ENV !== "development" && Backend,
        // lazily import resources on a fallback to reduce the bundle size
        resourcesToBackend((lng: string, ns: string) => import(`./locales/${lng}/${ns}.json`)),
      ]),
      backendOptions: compact([
        VITE_ENV !== "development" && {
          loadPath:
            VITE_ENV === "production"
              ? VITE_TRANSLATIONS_BASE_URL
              : `${VITE_TRANSLATIONS_BASE_URL}${new Date().getTime()}`,
          crossDomain: true,
        },
        {},
      ]),
    },
    react: {
      useSuspense: false,
    },
  });

export default i18nConfig;
